import "./About.css"

export default () => {
  return (
    <div className="about">

      <div className="about-paragraph">
        <p>
          <span>
            Hello! My name is Annee. I'm a British-Thai fantasy artist currently based in the Netherlands with my partner and our spoiled cat.
          </span>

          <span>
            I love to paint and draw all things sweet and strange. I primarily work digitally and enjoy exploring and expressing myself with colour and brushwork to create surreal, fantastical scenes and relatable characters.
          </span>

          <span>
            For any enquiries, please contact me by email at
            <a href="mailto:anneenaro@gmail.com">
              <img src="/media/email.png" className="email-image" width={200} style={{ }} />
            </a>
            .
          </span>

        </p>

        <img src="/media/smallmi.png" width={300} className="about-img" />
      </div>
    </div>
  )
}
