export const classes = (...cs: string[]) => cs.join(' ')

export const capitalise = (str: string) =>
  str.slice(0, 1).toUpperCase() + str.slice(1)

export const toggle = (b: boolean, className: string) => ' ' + (b ? className : '') + ' '

export const swap = <T>(arr: T[]) => (p1: number, p2: number) => {
  const e1 = arr[p1]
  const e2 = arr[p2]
  arr[p2] = e1
  arr[p1] = e2
}

export const isDev = process.env.NODE_ENV === 'development'

export const getNextId = <T extends { id: string }>(ts: T[]): string =>
  'id-' + (ts
    .map(t => Number.parseInt(t.id.replace('id-', ''), 10))
    .reduce((a, b) => Math.max(a, b)) + 1)
