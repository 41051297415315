import { Link, useLocation } from 'react-router-dom'
import { toggle } from '../util'
import SocialIcons from './SocialIcons'
import './Header.css'

export default () => {
  const location = useLocation()

  const isAbout = location.pathname.includes("about")
  const isPortfolio = !isAbout

  return (
    <header className='text-center header'>
      <Link to="/" className="logo-container">
        <img src="/media/logo.png" className="logo" />
      </Link>

      <Link to="/" className={toggle(isPortfolio, "current")}>Portfolio</Link>
      <Link to="/about" className={toggle(isAbout, "current")} >About</Link>

      <SocialIcons />
    </header>
  )
}
