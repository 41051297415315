import {
  createHashRouter,
  Link,
  Outlet,
  RouteObject,
  RouterProvider
} from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { useCurrentProject } from '../state'
import { isDev } from '../util'
import About from './About'
import Gallery from './Gallery'
import EditProject from './projects/edit/EditProject'
import EditProjects from './projects/edit/EditProjects'
import Header from './Header'

const Layout = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className="layout">
      <Header />

      <div id='main'>
        <Outlet />
      </div>

      <footer>
        © Annee Narongchai {currentYear}
      </footer>
    </div>
  )
}

const EditProjectWrapper = () => {
  const project = useCurrentProject()
  return <EditProject project={project!} />
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <div className='content'><Gallery /></div>
      },
      {
        path: '/about',
        element: <About />
      }
    ]
  }
]

const router = createHashRouter(
  isDev
    ? routes.concat([
      {
        path: '/projects/:id/edit',
        element: <EditProjectWrapper />
      },
      {
        path: '/projects/edit',
        element: <EditProjects />
      }]
    )
    : routes
)

function App() {
  return (
    <RecoilRoot>
      {/* fixed background doesn't work on IOS devices, so an image is used instead */}
      <img className='background-fixed-hack' src="/media/background.jpg" />

      <RouterProvider router={router} />
    </RecoilRoot>
  )
}

export default App
