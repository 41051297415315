import './SocialIcons.css'

const linkMap: Record<string, string> = {
  'instagram.png': 'https://www.instagram.com/vorpalbug/',
  'email.png': 'mailto:anneenaro@gmail.com',
  'artstation.png': 'https://www.artstation.com/a_n'
}

export default () => {
  return (<div className='social-icons'>
    {Object.entries(linkMap).map(([image, link]) => (
      <a href={link} key={link} target='_blank'>
        <img src={`/media/icons/${image}`} className={`social-icon`} />
      </a>
    ))}
  </div>)
}
