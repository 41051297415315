import { useParams } from "react-router-dom"
import { atom, selector, useRecoilState, useRecoilValue } from "recoil"
import projectsDataFromFile from '../state/projects.json'
import { getNextId, swap } from "../util"
import * as api from '../api'

export type Image = {
  id: string
  url: string
  description: string
  title: string
  fullWidth: boolean
  show: boolean
}

// TODO: add url slug
export type Project = {
  id: string
  title: string
  thumbnail: string
  tags: string[]
  images: Image[]
  description: string
}

// uncomment to add missing properties...
// let startId = 0
// projectsDataFromFile.forEach(p => {
//   p.images.forEach(img => {
//     // @ts-expect-error
//     img.id = img.id ?? `id-${startId++}`
//   })
// })

export const projectsState = atom({
  key: 'projectsState',
  default: JSON.parse(JSON.stringify(projectsDataFromFile)) as Project[]
})

export const tagsState = selector({
  key: 'tags',
  get: f => {
    return new Set(f.get(projectsState).flatMap(p => p.tags).map(t => t.toLowerCase().trim()))
  }
})

export const currentTag = atom<string | undefined>({
  default: undefined,
  key: 'currentTag'
})

export const filteredProjectsState = selector({
  key: 'filteredProjectsState',
  get: ({ get }) => {
    const tag = get(currentTag)
    const projects = get(projectsState)
    if (tag && tag.toLowerCase().trim() !== 'all')
      return projects.filter(p => p.tags.map(t => t.toLowerCase().trim()).includes(tag))
    else return projects
  }
})

export const useNextPrevProject = (): { next: Project, prev: Project } => {
  const { id } = useParams()
  const projects = useRecoilValue(filteredProjectsState)

  if (!id) return {
    next: projects[1],
    prev: projects[projects.length - 1]
  }

  const index = projects.findIndex(p => p.id === id)
  const next = (index + 1) % projects.length
  const prev = (index - 1 + projects.length) % projects.length
  return { next: projects[next], prev: projects[prev] }
}

export const useCurrentProject = (): Project => {
  const { id } = useParams()
  const projects = useRecoilValue(projectsState)
  if (id) {
    return projects.find(p => p.id === id) ?? projects[0]
  } else return projects[0]
}

export const useUpdateProject = (projectId: string) => {
  const [_, updateProjects] = useRecoilState(projectsState)

  return (f: (p: Project) => Project) =>
    updateProjects(projects =>
      projects.map(project => project.id === projectId
        ? f(project)
        : project
      )
    )
}

export const useUpdateProjectPos = (id: string) => {
  const [_, updateProjects] = useRecoilState(projectsState)

  const move = (findNewPos: (current: number, ps: Project[]) => number) => {
    updateProjects(ps => {
      const pos = ps.findIndex(i => i.id === id)
      const copy = ps.slice()
      swap(copy)(pos, findNewPos(pos, copy))
      return copy
    })
  }

  const up = () => move((pos, imgs) => (pos - 1 + imgs.length) % imgs.length)
  const down = () => move((pos, imgs) => (pos + 1) % imgs.length)

  return { up, down }
}

export const useAddImage = (projectId: string) => {
  const update = useUpdateProject(projectId)
  return (url: string) => update(p => ({
    ...p,
    images: [
      {
        id: getNextId(p.images),
        description: '',
        fullWidth: false,
        show: true,
        title: '',
        url
      },
      ...p.images
    ]
  }))
}

export const useUpdateImage = () => {
  const [_, updateProjects] = useRecoilState(projectsState)
  return (id: string) => (newImage: Image) =>
    updateProjects(projects =>
      projects.map(p =>
      ({
        ...p,
        images: p.images.map(img => img.id === id ? newImage : img)
      })
      )
    )
}

export const useImage = (id: string): [Image | undefined, (i: Image) => void] => {
  const projects = useRecoilValue(projectsState)
  const updateImg = useUpdateImage()

  return [
    projects.flatMap(p => p.images).find(i => i.id === id),
    updateImg(id)
  ]
}

export const useUpdateImages = (projectId: string) => {
  const [_, updateProjects] = useRecoilState(projectsState)
  return (f: (images: Image[]) => Image[]) => updateProjects(ps => ps.map(p =>
    p.id === projectId
      ? ({ ...p, images: f(p.images) })
      : p
  ))
}

export const useUpdateImagePos = (projectId: string) => {
  const updateImages = useUpdateImages(projectId)

  return (id: string) => {
    const move = (findNewPos: (current: number, imgs: Image[]) => number) => {
      updateImages(images => {
        const pos = images.findIndex(i => i.id === id)
        const copy = images.slice()
        swap(copy)(pos, findNewPos(pos, copy))
        return copy
      })
    }

    const up = () => move((pos, imgs) => (pos - 1 + imgs.length) % imgs.length)
    const down = () => move((pos, imgs) => (pos + 1) % imgs.length)

    return { up, down }
  }
}

export const useAddNewProject = () => {
  const [_, updateProjects] = useRecoilState(projectsState)

  return () => {
    updateProjects(ps => [{
      description: 'project',
      images: [],
      id: getNextId(ps),
      tags: [],
      thumbnail: '',
      title: 'Project'
    }, ...ps])
  }
}

export const useDeleteImage = (projectId: string) => {
  const updateProject = useUpdateProject(projectId)
  return async (imageId: string) => {
    await api.deleteImage(projectId, imageId)
    updateProject(project => ({
      ...project,
      images: project.images.filter(i => i.id !== imageId)
    }))
  }
}

export const useDeleteProject = (projectId: string) => {
  const [projects, setProjects] = useRecoilState(projectsState)
  const deleteImage = useDeleteImage(projectId)

  return async () => {
    const project = projects.find(p => p.id === projectId)
    if (!project) return
    console.log('deleting all project images...', project.images)
    await Promise.all(project.images.map(i => i.id).map(deleteImage))
    console.log('deleted all project images')
    setProjects(ps => ps.filter(p => p.id !== projectId))
  }
}
